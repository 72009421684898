<template>
    <v-container fluid fill-height style="justify-content: center">
        <div>
            <alert ref="alertComponent"></alert>
            <video-form ref="videoForm" :isUpdate="true" @submit="submit($event)"></video-form>
        </div>
    </v-container>
</template>

<script>
import Alert from "../../components/Alert.component";
import VideoForm from "../../components/VideoForm.component";
import Services from '../../services/api';

export default {
    name: "Video",
    components: {Alert, VideoForm},
    data: () => ({
        video: {},
        allVideos: [],
        playlists: [],
    }),
    async mounted() {
        this.id = parseInt(this.$route.params.id);
        try {
            // TODO do it in parallel
            this.allVideos = await Services.Videos.getAllVideos();
            this.playlists = await Services.Playlists.getAllPlaylists();
            this.video = await Services.Videos.getVideo(this.id);
        } catch (e) {
            this.$refs.alertComponent.showErrorMessage(e.message);
        }
        this.$refs.videoForm.setVideo(this.video);
        this.$refs.videoForm.setVideos(this.allVideos);
        this.$refs.videoForm.setPlaylists(this.playlists);
    },
    methods: {
        /**
         * send the update request to the api
         * @returns {Promise<void>}
         */
        async submit(payload) {
            try {
                this.video = await Services.Videos.updateVideo(payload);

                this.$refs.alertComponent.showSuccessMessage("Video successfully updated");
                this.$refs.videoForm.setVideo(this.video);
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
            this.$refs.videoForm.resetDefault();
        }
    }
}
</script>

<style scoped>

</style>